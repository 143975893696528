import React from 'react';
import MasterLayout from './MasterLayout';

const InvestmentTracking: React.FC = () => {
  return (
    <MasterLayout>
      <div className="screen-container">
        <div className="coming-soon-container">
          <p>/* coming soon */</p>
        </div>
      </div>
    </MasterLayout>
  );
};

export default InvestmentTracking;
