import React, { useState, useEffect } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './PhoneOTP.css'; // Import the updated CSS

// Firebase configuration from environment variables
const firebaseConfig = {
    apiKey: "AIzaSyDYaB1qBA3VqmMhmQx_MBCCVwV1J8OAkN4",
    authDomain: "vayam-727a5.firebaseapp.com",
    projectId: "vayam-727a5",
    storageBucket: "vayam-727a5.appspot.com",
    messagingSenderId: "701843144459",
    appId: "1:701843144459:web:5dd098b19b67d97a78ec0e",
    measurementId: "G-61RYXKE276"
};

// Initialize Firebase app and Auth
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}

const PhoneOTPLogin: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [otpSent, setOtpSent] = useState(false); // Control when to show OTP input
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false); // Track whether reCAPTCHA is loaded

  useEffect(() => {
    if (!recaptchaLoaded && document.getElementById('recaptcha-container')) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
          callback: (response: any) => {
            console.log('Recaptcha verified', response);
          },
        });

        window.recaptchaVerifier.render()
          .then(() => {
            setRecaptchaLoaded(true); // Ensure reCAPTCHA is only loaded once
          })
          .catch((error: any) => {
            console.error('Error rendering recaptcha', error);
          });
      } catch (error) {
        console.error('RecaptchaVerifier initialization error:', error);
      }
    }
  }, [recaptchaLoaded]);

  const handleSendOTP = async () => {
    const phoneNumberWithCountryCode = `+91${phoneNumber}`; // Customize according to your needs
    const appVerifier = window.recaptchaVerifier;

    try {
      const result = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier);
      setConfirmationResult(result);
      setOtpSent(true); // Show OTP input after OTP is sent successfully
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const result = await confirmationResult.confirm(otp);
      const idToken = await result.user.getIdToken();

      // Send the ID token to your Node.js backend using axios
      const response = await axios.post('https://theekapi.azurewebsites.net/api/auth/verify-otp', {
        otp: idToken,
      });

      const data = response.data;
      localStorage.setItem('token', data.token); // Save the JWT token
      navigate('/home'); // Navigate to home screen after successful login
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className="phone-otp-container">
      <div className="phone-otp-card">
        <h2 className="phone-otp-title">Login with Phone</h2>

        {/* Phone number input section */}
        {!otpSent && (
          <div>
            <input
              className="input-field"
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <button className="otp-button" onClick={handleSendOTP}>Send OTP</button>
          </div>
        )}

        {/* OTP input section (only visible after OTP is sent) */}
        {otpSent && (
          <div>
            <p className="otp-message">OTP sent successfully! Please enter the OTP.</p>
            <input
              className="input-field"
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button className="otp-button" onClick={handleVerifyOTP}>Verify OTP</button>
          </div>
        )}

        <div id="recaptcha-container"></div> {/* Invisible reCAPTCHA */}
      </div>
    </div>
  );
};

export default PhoneOTPLogin;
