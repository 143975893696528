import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { FaUser, FaRobot } from 'react-icons/fa';
import './MasterLayout.css';

interface MasterLayoutProps {
  children: React.ReactNode;
}

const MasterLayout: React.FC<MasterLayoutProps> = ({ children }) => {
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve and decode JWT token to extract user details
  const token = localStorage.getItem('token');
  let userDetails = { userId: '', name: '', email: '' };

  if (token) {
    try {
      const decodedToken: any = jwtDecode(token);
      userDetails.userId = decodedToken.userId || '';
      userDetails.name = decodedToken.name || 'User';
      userDetails.email = decodedToken.email || '';
    } catch (error) {
      console.error('Failed to decode JWT token', error);
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const toggleProfile = () => {
    setShowProfile((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const profilePopup = document.querySelector('.profile-popup');
    const profileIcon = document.querySelector('.profile-container');

    if (
      showProfile &&
      profilePopup &&
      !profilePopup.contains(event.target as Node) &&
      profileIcon &&
      !profileIcon.contains(event.target as Node)
    ) {
      setShowProfile(false);
    }
  };

  useEffect(() => {
    if (showProfile) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showProfile]);

  const getLinkClassName = (path: string) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  return (
    <div className="master-layout">
      <header className="header">
        <div className="logo-nav">
          <div className="logo">
            <Link to="/home">
              Vayam AI
              <span className="logo-subscript">DIY Wealth</span>
            </Link>
          </div>
          <nav className="nav-links">
            <Link to="/personal-financial-statement" className={getLinkClassName('/personal-financial-statement')}>
              Personal Financial Health - PFS
            </Link>
            <Link to="/financial-planning" className={getLinkClassName('/financial-planning')}>
              Financial Planning
            </Link>
            <Link to="/investment-tracking" className={getLinkClassName('/investment-tracking')}>
              Investment & Tracking
            </Link>
          </nav>
        </div>
        <div className="icons">
          <div className={`icon-label-container ${location.pathname === '/FinGPT' ? 'active' : ''}`}>
            <Link to="/FinGPT" className="icon-link">
              <FaRobot className="icon" />
            </Link>
            <span className="icon-label">FinGPT</span>
          </div>

          <div className="icon-label-container profile-container" onClick={toggleProfile}>
            <FaUser className="icon" />
            <span className="icon-label">You</span>
            {showProfile && (
              <div className="profile-popup">
                <div className="profile-details">
                  <strong>{userDetails.name}</strong>
                  {userDetails.email && <p>{userDetails.email}</p>}
                </div>
                <button className="logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      <main className="main-content">{children}</main>

      <footer className="footer">
        <p>&copy; 2024 Vayam AI. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default MasterLayout;
