import React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  currentStep: number;
  steps: { title: string }[];
  onStepClick: (index: number) => void;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, steps, onStepClick }) => {
  return (
    <div className="progress-bar">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`progress-step ${index <= currentStep ? 'active' : ''}`}
          onClick={() => onStepClick(index)}
        >
          <span>{step.title}</span>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
