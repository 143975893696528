import React, { useState, useEffect, useRef } from 'react';
import './CustomSlider.css';

interface CustomSliderProps {
  min: number;
  max: number;
  value: number;
  scale: number; // Scale for the interval between ticks
  onChange: (value: number) => void;
}

const CustomSlider: React.FC<CustomSliderProps> = ({ min, max, value, scale, onChange }) => {
  const range = max - min;
  const ticks = Array.from({ length: Math.floor(range / scale) + 1 }, (_, i) => min + i * scale);

  const sliderRef = useRef<HTMLInputElement>(null);
  const [labelPosition, setLabelPosition] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const newLabelPosition = ((value - min) / range) * sliderWidth;
      setLabelPosition(newLabelPosition);
    }
  }, [value, min, max]);

  return (
    <div className="custom-slider-container">
      <div
        className="slider-value-display"
        style={{ left: `${labelPosition}px` }}
      >
        {value}
      </div>
      <input
        ref={sliderRef}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="slider"
      />
      <div className="scale">
        {ticks.map((tick, index) => (
          <div
            key={index}
            className="tick"
            style={{ left: `${((tick - min) / range) * 100}%` }}
          >
            <div className="tick-mark" />
            <div className="tick-label">{tick}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomSlider;
