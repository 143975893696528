// Define the structure of a question option
interface Option {
  value: string;
  label: string;
}

export interface Question {
  id: number;
  title: string;
  question: string;
  tag: string; // Generalized tag
  type: "radio" | "checkbox" | "input" | "date" | "slider"; // Added "slider" for range slider input
  options?: Option[]; // Made optional to allow input-type questions
  min?: number; // Minimum value for the slider
  max?: number; // Maximum value for the slider
  scale?: number; // Scale interval for the slider
  hint?: string;
}



export const personalInfoQuestions: Question[] = [
  {
    id: 1,
    title: "About you",
    question: "Your name , age and what do you do",
    tag: "aboutyou",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos."
  },
  {
    id: 2,
    title: "About your dependents",
    question: "Family members financially dependent on you",
    tag: "aboutdependent",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos."
  },
  
];


// Income Questions
export const incomeQuestions: Question[] = [
  {
    id: 1,
    title: "Monthly Income",
    question: "What is your monthly gross income?",
    tag: "monthlyIncome",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  },
  // {
  //   id: 2,
  //   title: "Income Sources",
  //   question: "What are your sources of income?",
  //   tag: "incomeSources",
  //   type: "checkbox",
  //   options: [
  //     { value: "Salary", label: "Salary" },
  //     { value: "Business", label: "Business" },
  //     { value: "Freelance", label: "Freelance" },
  //     { value: "Rental Income", label: "Rental Income" },
  //     { value: "Investments", label: "Investments" },
  //     { value: "Other", label: "Other" }
  //   ]
  // },
  // {
  //   id: 3,
  //   title: "Bonuses or Incentives",
  //   question: "Do you receive any bonuses or incentives? If yes, what is their value?",
  //   tag: "bonusesIncentives",
  //   type: "input"
  // }
  {
    id: 4,
    title: "MonthlySaving",
    question: "How much monthly savings you can invest ",
    tag: "MonthlySaving",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  }
];

// Expense Questions
export const expenseQuestions: Question[] = [
  {
    id: 1,
    title: "Monthly Fixed Expenses",
    question: "What are your average monthly fixed expenses?",
    tag: "monthlyFixedExpenses",
    type: "input"
  },
  {
    id: 2,
    title: "Monthly Discretionary Expenses",
    question: "What are your average monthly discretionary expenses?",
    tag: "monthlyDiscretionaryExpenses",
    type: "input"
  },
  {
    id: 3,
    title: "Significant Upcoming Expenses",
    question: "Do you have any significant upcoming expenses?",
    tag: "upcomingExpenses",
    type: "checkbox",
    options: [
      { value: "Vacation", label: "Vacation" },
      { value: "Car Purchase", label: "Car Purchase" },
      { value: "Home Renovation", label: "Home Renovation" },
      { value: "Other", label: "Other" }
    ]
  }
];

// Assets Questions
export const assetsQuestions: Question[] = [
  {
    id: 1,
    title: "Lets know about your income and expenses",
    question: "Lets know about your income and expenses",
    tag: "income&expenses",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  
  },
  {
    id: 2,
    title: "Lets know what all assets you own",
    question: "Lets know what assets all you own",
    tag: "assets",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  
  },
  {
    id: 3 ,
    title: "Lets know where all you invest",
    question: "Lets know where all you invest",
    tag: "investments",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  
  },
  {
    id: 4 ,
    title: "Lets know about liabilities",
    question: "Lets know about liabilities",
    tag: "liabilities",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  
  },

];

// Liabilities Questions
export const liabilitiesQuestions: Question[] = [
  {
    id: 1,
    title: "Loans or Debt",
    question: "Do you have any outstanding loans or debt? Please specify.",
    tag: "outstandingDebt",
    type: "checkbox",
    options: [
      { value: "Home Loan", label: "Home Loan" },
      { value: "Car Loan", label: "Car Loan" },
      { value: "Personal Loan", label: "Personal Loan" },
      { value: "Credit Card Debt", label: "Credit Card Debt" }
    ]
  },
  // {
  //   id: 2,
  //   title: "Monthly Debt Payments",
  //   question: "What are your current monthly debt payments?",
  //   tag: "monthlyDebtPayments",
  //   type: "input"
  // },
  // {
  //   id: 3,
  //   title: "Remaining Loan Balances",
  //   question: "What is the remaining balance of each loan or debt?",
  //   tag: "remainingLoanBalance",
  //   type: "input"
  // }
];

// Insurance Questions
export const insuranceQuestions: Question[] = [

  
  {
    id: 1,
    title: "Rainy Days",
    question: "What are you plan for rainy days",
    tag: "rainydays",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  }
];

// Retirement Planning Questions
export const retirementQuestions: Question[] = [
  {
    id: 1,
    title: "Retirement Plans",
    question: "Do you currently contribute to any retirement plans?",
    tag: "retirementPlans",
    type: "checkbox",
    options: [
      { value: "EPF", label: "EPF" },
      { value: "NPS", label: "NPS" },
      { value: "Private Pension Plan", label: "Private Pension Plan" }
    ]
  },
  {
    id: 2,
    title: "Retirement Account Balance",
    question: "What is the current balance in your retirement accounts?",
    tag: "retirementBalance",
    type: "input"
  },
  {
    id: 3,
    title: "Desired Retirement Age",
    question: "What is your desired retirement age?",
    tag: "retirementAge",
    type: "input"
  },
  {
    id: 4,
    title: "Ideal Retirement Lifestyle",
    question: "What is your ideal retirement lifestyle? (e.g., travel frequency, standard of living)",
    tag: "retirementLifestyle",
    type: "input"
  }
];

// Goals and Objectives Questions
export const goalsQuestions: Question[] = [
  
  {
    id: 1,
    title: "Wealth Goals",
    question: "let's know your wealth goals in near future and long term ",
    tag: "wealthgoals",
    type: "input",
    hint:"Detailed information would help me provide better insights and personalised suggestions...I am capable of ignoring language typos.",
  }
];

// Taxation Questions
export const taxationQuestions: Question[] = [
  {
    id: 1,
    title: "Tax Filing",
    question: "Do you currently file taxes?",
    tag: "taxFiling",
    type: "radio",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" }
    ]
  },
  {
    id: 2,
    title: "Annual Tax Liability",
    question: "What is your approximate annual tax liability?",
    tag: "taxLiability",
    type: "input"
  },
  {
    id: 3,
    title: "Tax-saving Investments",
    question: "Do you utilize any tax-saving investments or schemes?",
    tag: "taxSavingInvestments",
    type: "checkbox",
    options: [
      { value: "PPF", label: "PPF" },
      { value: "ELSS", label: "ELSS" },
      { value: "NSC", label: "NSC" },
      { value: "HRA Deductions", label: "HRA Deductions" }
    ]
  }
];

// Other Considerations Questions
export const otherConsiderationsQuestions: Question[] = [
  {
    id: 1,
    title: "Major Life Events",
    question: "Do you anticipate any major life events that could impact your finances?",
    tag: "lifeEvents",
    type: "checkbox",
    options: [
      { value: "Marriage", label: "Marriage" },
      { value: "Childbirth", label: "Childbirth" },
      { value: "Relocation", label: "Relocation" }
    ]
  },
  {
    id: 2,
    title: "Financial Health Rating",
    question: "How would you rate your overall financial health?",
    tag: "financialHealth",
    type: "radio",
    options: [
      { value: "Poor", label: "Poor" },
      { value: "Fair", label: "Fair" },
      { value: "Good", label: "Good" },
      { value: "Excellent", label: "Excellent" }
    ]
  },
  {
    id: 3,
    title: "Estate Planning",
    question: "Do you have a will or estate plan in place?",
    tag: "estatePlan",
    type: "radio",
    options: [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" }
    ]
  }
];
