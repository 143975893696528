import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import './Step.css';
import axios from 'axios';
import {
  personalInfoQuestions,
  assetsQuestions,
  insuranceQuestions,
  goalsQuestions,
  Question,
} from './Questions';
import { FaRobot, FaUser } from 'react-icons/fa';

interface SummaryStepProps {
  onPrevSection: () => void;
}

interface Answer {
  questionTag: string;
  answer: string;
}

interface FinancialData {
  answers: Record<string, string>;
}

const SummaryStep: React.FC<SummaryStepProps> = ({ onPrevSection }) => {
  const [financialData, setFinancialData] = useState<FinancialData>({ answers: {} });
  const [aiInsights, setAIInsights] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();  // Initialize the useNavigate hook

  const allSections = [
    { title: 'You and Your Family', questions: personalInfoQuestions },
    { title: 'Your Wealth', questions: assetsQuestions },
    { title: 'Your Rainy days plan', questions: insuranceQuestions },
    { title: 'Your Wealth Goals', questions: goalsQuestions },
  ];

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return process.env.REACT_APP_ENV !== 'development' && token
      ? { Authorization: `Bearer ${token}` }
      : {};
  };

  useEffect(() => {
    const fetchUserFinancialData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userFinancialData`, {
          headers: getAuthHeaders(),
        });
        if (response.status === 401) {
          // Token is invalid or expired, clear it from localStorage
          localStorage.removeItem('token');
  
          // Redirect to login page
          navigate('/login');  // Redirect to login using React Router
          return;
        }
        const userAnswers: Record<string, string> = response.data.answers.reduce(
          (acc: Record<string, string>, answer: Answer) => {
            acc[answer.questionTag] = answer.answer;
            return acc;
          },
          {}
        );

        setFinancialData({ answers: userAnswers });
      } catch (err) {
        console.error('Error fetching user financial data:', err);
        setError('Failed to load financial data. Please try again.');
      }
    };

    fetchUserFinancialData();
  }, []);

  const handleFetchAIInsights = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const message = `${process.env.REACT_APP_INSIGHT_MESSAGE}:\n\n${formatUserDataForAI(financialData.answers)}`;

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/message`,
        { message, category: 'finance' },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { assistantResponse } = response.data;
      setAIInsights(assistantResponse);
    } catch (error) {
      console.error('Error fetching AI insights:', error);
      setAIInsights('There was an error processing your request.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatUserDataForAI = (userAnswers: Record<string, string>): string => {
    return Object.entries(userAnswers)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
  };

  const handleNavigateToFinGPT = (insight: string) => {
    const messageToPass = `Help me understand this more so that I could take action on it: ${insight}`;
    navigate('/fingpt', { state: { insight: messageToPass } });  // Pass the modified insight with the additional text
  };

  const handleAssetAllocation = () => {
    const messageToPass = `Advise me on my asset allocation`;
    navigate('/fingpt', { state: { insight: messageToPass } }); // Example navigation to asset allocation page
  };

  const handleChatWithFinGPT = () => {
    navigate('/fingpt');
  };

  const renderSection = (title: string, questions: Question[]) => (
    <div key={title} className="summary-section">
      <h4>{title}</h4>
      <ul>
        {questions.map((question) => (
          <li key={question.tag} className="summary-item">
            <strong>{question.title}:</strong> {financialData.answers[question.tag] || '____'}
          </li>
        ))}
      </ul>
    </div>
  );

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="summary-container">
      <div className="summary-column-left">
        <FaUser className="robot-icon" size={30} />
        <div className="summary-content">
          {allSections.map((section) => renderSection(section.title, section.questions))}
        </div>
      </div>
      <div className="summary-column-right">
        <div className="button-row">
          <FaRobot className="robot-icon" size={40} />
          <button onClick={handleFetchAIInsights} disabled={isLoading} className="main-button">
            {isLoading ? 'Analyzing...' : 'Personal AI Insights'}
          </button>

          {/* Conditionally display these buttons after AI Insights are generated */}
          {aiInsights && (
            <>
              <button onClick={handleAssetAllocation} className="additional-button">
                Asset Allocation
              </button>
              <button onClick={handleChatWithFinGPT} className="additional-button">
                Chat with FinGPT
              </button>
            </>
          )}
        </div>

        {aiInsights && (
          <div className="ai-insights">
            <ul>
              {aiInsights
                .split('\n')
                .filter((insight) => insight.trim() !== '')  // Filter out empty lines
                .map((insight, index) => (
                  index === 0 ? (  // First insight as non-bullet, non-button
                    <div key={index} className="non-bullet-insight">
                      {insight}
                    </div>
                  ) : (
                    <li key={index}>
                      <button onClick={() => handleNavigateToFinGPT(insight)} className="insight-button">
                        {insight}
                      </button>
                    </li>
                  )
                ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryStep;
