import React from 'react';
import { FaGoogle, FaMobile, FaMobileAlt, FaPhone } from 'react-icons/fa'; // Import icons
import './Login.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=https://theekapi.azurewebsites.net/api/auth/callback&response_type=code&scope=email%20profile&access_type=offline`;

    const navigate = useNavigate();

    // Navigate to phone OTP login screen
    const handlePhoneLogin = () => {
        navigate('/phoneOTP');
    };

    return (
        <div className="login-container">
            <div>
                <h2>Welcome to Vayam AI</h2>
            </div>
            <div className="login-buttons">
                <a href={googleAuthUrl} className="login-button google">
                    <FaGoogle className="login-icon" />
                    <span>Login with Google</span>
                </a>
                <button className="login-button phone" onClick={handlePhoneLogin}>
                    <FaMobileAlt className="login-icon" />
                    <span>Login with Phone</span>
                </button>
            </div>
        </div>
    );
};

export default Login;
