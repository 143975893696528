import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeScreen.css'; // Custom styling
import { FaRobot } from 'react-icons/fa';
// import MasterLayout from './MasterLayout';

const PreLoginHome: React.FC = () => {
  const navigate = useNavigate();

  return (
   
      <div className="home-screen">
       <FaRobot className="robot-icon" size={60} />
       <h1 className="greeting"> I am a personal finance AI</h1>
        <div className="features-container">
          <div className="feature-item">
            <i className="feature-icon fas fa-chart-line"></i>
            <h2 className="feature-title">Maximise your assets and investments</h2>
            <p className="feature-description">Unlock the full potential of your assets and investments! Maximise your returns by fine-tuning your financial strategy for greater growth.</p>
            <button className="cta-button" onClick={() => navigate('/personal-financial-statement')}>
            PFS and Advisor Insights
            </button>
          </div>
          <div className="feature-item">
            <i className="feature-icon fas fa-robot"></i>
            <h2 className="feature-title">Consult with your personalised Fin-AI</h2>
            <p className="feature-description">Get instant answers and expert guidance on all your financial queries with our personalised fin AI!</p>
            <button className="cta-button" onClick={() => navigate('/FinGPT')}>
            Talk to your FinGPT
            </button>
          </div>

          <div className="feature-item">
            <i className="feature-icon fas fa-lightbulb"></i>
            <h2 className="feature-title">Supercharge your financial future & wealth creation!</h2>
            <p className="feature-description">We'll help you plan, invest, and track your way to achieving your ultimate financial goals and dreams.</p>
            <button className="cta-button" onClick={() => navigate('/financial-planning')}>
            Start Investing
            </button>
          </div>

          
        </div>
      </div>
    
  );
};

export default PreLoginHome;
