import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './login';
import Callback from './callback';
import AIAdvisor from './AiAdvisor';
import ProtectedRoute from './ProtectedRoute';
import HomeScreen from './HomeScreen';
import PersonalFinancialStatement from './PersonalFinancialStatement';
import InvestmentTracking from './InvestmentTracking';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import PreLoginHome from './PreLoginHome';
import PhoneOTPLogin from './PhoneOTPLogin';
import FinGPT from './FinGPT';

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
// };




const App: React.FC = () => {
  
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<PreLoginHome />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/callback" element={<Callback />} />
        <Route path="/phoneOTP" element={<PhoneOTPLogin/>}/>
        {/* for dev testing purpose avoiding login reroute not to be check in code*/}
        {/* <Route path="/home" element={<HomeScreen />} />
        <Route path="/personal-financial-statement" element={<PersonalFinancialStatement />} />
        <Route path="/financial-planning" element={<InvestmentTracking />} />
        <Route path="/investment-tracking" element={<InvestmentTracking />} />
        <Route path="/FinGPT" element={<AIAdvisor />} /> */}
        {/*
         
        {/* Protected routes */}
        
        <Route 
          path="/home" 
          element={
            <ProtectedRoute>
              <HomeScreen />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/personal-financial-statement" 
          element={
            <ProtectedRoute>
              <PersonalFinancialStatement />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/financial-planning" 
          element={
            <ProtectedRoute>
              < InvestmentTracking/>
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/FinGPT" 
          element={
            <ProtectedRoute>
              <FinGPT />
            </ProtectedRoute>
          } 
        />
       
        <Route 
          path="/investment-tracking" 
          element={
            <ProtectedRoute>
              <InvestmentTracking />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
};

export default App;
