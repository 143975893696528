import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Callback: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');
        if (token) {
            localStorage.setItem('token', token);
            navigate('/home');
        } else {
            console.error('No token found in URL');
        }
    }, [location, navigate]);

    return <div>Loading...</div>;
};

export default Callback;
