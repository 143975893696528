import React, { useState, useEffect, useCallback } from 'react';
import './Step.css';
import { assetsQuestions } from './Questions';
import axios from 'axios';
import { debounce } from './Utils/Debounce';
import CustomSlider from './CustomSlider';
import { FaRobot, FaUser } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface AssetsStepProps {
  onNextSection: () => void;
  onPrevSection: () => void;
}

const AssetsStep: React.FC<AssetsStepProps> = ({ onNextSection, onPrevSection }) => {
  const [step, setStep] = useState(1);
  const [assetsData, setAssetsData] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false); // Removed initial blocking loading state
  const navigate=useNavigate();

  const currentQuestion = assetsQuestions[step - 1];

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return process.env.REACT_APP_ENV !== 'development' && token
      ? { Authorization: `Bearer ${token}` }
      : {};
  };

  // Debounced save answer function
  const saveAnswer = useCallback(
    debounce(async (questionTag: string, answer: string) => {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/userFinancialData`,
          { questionTag, answer },
          { headers: getAuthHeaders() }
        );
      } catch (error) {
        console.error(`Error saving answer for ${questionTag}:`, error);
      }
    }, 500),
    []
  );

  // Fetch the existing answers for the assets section in the background
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userFinancialData`, {
          headers: getAuthHeaders(),
        });
        // Check if the response is a 401 Unauthorized
      if (response.status === 401) {
        // Token is invalid or expired, clear it from localStorage
        localStorage.removeItem('token');

        // Redirect to login page
        navigate('/login');  // Redirect to login using React Router
        return;
      }
        const userData = response.data.answers.reduce(
          (acc: Record<string, string>, answer: { questionTag: string; answer: string }) => {
            acc[answer.questionTag] = answer.answer;
            return acc;
          },
          {}
        );
        setAssetsData(userData);
      } catch (error) {
        console.error('Error fetching user financial data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = useCallback((field: string, value: string) => {
    const newData = { ...assetsData, [field]: value };
    setAssetsData(newData);  // Optimistically update UI
    saveAnswer(field, value);  // Save data in the background
  }, [assetsData, saveAnswer]);

  const handleNext = useCallback(() => {
    if (step < assetsQuestions.length) {
      setStep(step + 1);
    } else {
      onNextSection();  // Move to the next section (e.g., Liabilities)
    }
  }, [step, onNextSection]);

  const handleBack = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onPrevSection();  // Move to the previous section (e.g., Income)
    }
  }, [step, onPrevSection]);

  return (
    <div className="step-container">
      {currentQuestion ? (
        <>
          <div className="ai-question-container">
            <div className="robot-avatar">
              <FaRobot className="robot-icon" />
            </div>
            <div className="ai-question-bubble">
              
              <h4>{currentQuestion.question}</h4>
              <label className="question-label"> 
                {currentQuestion.hint?.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </label>
              <div className="security-message">
        <p>Your data is end-to-end encrypted, confidential, and accessible only to you.</p>
      </div>
            </div>
          </div>

          <div className="user-input-container">
            {currentQuestion.type === 'radio' || currentQuestion.type === 'checkbox' ? (
              currentQuestion.options?.map((option) => (
                <div key={option.value} className="user-response">
                  <textarea
                    className="user-input-bubble"
                    value={assetsData[currentQuestion.tag] || ''}
                    onChange={(e) => handleInputChange(currentQuestion.tag, e.target.value)}
                  />
                  <div className="user-avatar">
                    <FaUser className="user-icon" />
                  </div>
                </div>
              ))
            ) : currentQuestion.type === 'input' ? (
              <div className="user-response">
                <textarea
                  className="user-input-bubble"
                  value={assetsData[currentQuestion.tag] || ''}
                  onChange={(e) => handleInputChange(currentQuestion.tag, e.target.value)}
                />
                <div className="user-avatar">
                  <FaUser className="user-icon" />
                </div>
              </div>
            ) : currentQuestion.type === 'slider' ? (
              <CustomSlider
                min={currentQuestion.min!}
                max={currentQuestion.max!}
                value={Number(assetsData[currentQuestion.tag] || currentQuestion.min)}
                scale={currentQuestion.scale!}  // Ensure the scale is passed here
                onChange={(value) => handleInputChange(currentQuestion.tag, value.toString())}
              />
            ) : null}
          </div>
        </>
      ) : (
        <div>No questions available</div>
      )}
   
      <div className="navigation-buttons">
  <button onClick={handleBack}>
    {step === 1 ? '<<' : '<'} {/* Shows '<<' for the first step and '<' for others */}
  </button>
  <button onClick={handleNext}>
    {step === assetsQuestions.length ? '>>' : '>'} {/* Shows '>>' for the last step and '>' for others */}
  </button>
</div>

    </div>
  );
};

export default AssetsStep;
