import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import MasterLayout from './MasterLayout';
import { FaUser, FaRobot } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';

const FinGPT: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const insight = location.state?.insight || '';

  const [message, setMessage] = useState(insight);
  const [chatHistory, setChatHistory] = useState<{ role: string, content: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const addMessageToHistory = (role: string, content: string) => {
    setChatHistory((prev) => [...prev, { role, content }]);
  };

  const updateLastMessageInHistory = (role: string, content: string) => {
    setChatHistory((prev) => {
      const updatedHistory = [...prev];
      const lastMessageIndex = updatedHistory.length - 1;
      if (lastMessageIndex >= 0 && updatedHistory[lastMessageIndex].role === role) {
        updatedHistory[lastMessageIndex].content = content;
      }
      return updatedHistory;
    });
  };

  const handleSubmit = async () => {
    if (!message.trim()) return;

    addMessageToHistory('user', message);
    setIsLoading(true);
    setMessage('');

    addMessageToHistory('assistant', 'Analyzing...');

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/finGPTMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          message: message,
          category: 'finance',
        }),
      });

      if (response.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
        return;
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';
      let buffer = '';

      const read = async () => {
        const { done, value } = await reader?.read() || { done: true, value: undefined };
        if (done) {
          setIsTyping(false);
          setIsLoading(false);
          return;
        }

        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        const lines = buffer.split('data: ').filter(line => line.trim() !== '');

        for (const line of lines) {
          try {
            const parsed = JSON.parse(line);
            const deltaContent = parsed.choices[0]?.delta?.content || '';

            if (deltaContent) {
              assistantMessage += deltaContent;
              updateLastMessageInHistory('assistant', assistantMessage.trim());
            }

          } catch (error) {
            console.error('Error parsing stream chunk:', error);
          }
        }

        buffer = '';
        read();
      };

      setIsTyping(true);
      updateLastMessageInHistory('assistant', '');
      read();

    } catch (error) {
      console.error('Error with streaming:', error);
      updateLastMessageInHistory('assistant', 'There was an error processing your request.');
      setIsTyping(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  return (
    <MasterLayout>
      <div style={styles.container}>
        <div style={styles.chatContainer}>
          <div style={styles.resultBox}>
            {chatHistory.map((entry, index) => (
              <div key={index} style={styles.messageContainer(entry.role)}>
                <div style={styles.iconContainer}>
                  {entry.role === 'user' ? <FaUser style={styles.icon(entry.role)} /> : <FaRobot style={styles.icon(entry.role)} />}
                </div>
                <div style={styles.message(entry.role)}>
                  <div style={styles.message(entry.role)}>
                    <ReactMarkdown>
                      {entry.content
                        .replace(/\s+/g, ' ')
                        .replace(/(\d+)\. /g, '\n$1. ') // Adding line breaks before numbered lists
                        .replace(/\n/g, '  \n') // Forcing line breaks using Markdown syntax
                        .trim()}
                    </ReactMarkdown>
                  </div>

                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div style={styles.inputContainer}>
            <textarea
              style={styles.textArea}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              placeholder="DIY Wealth - fusion of AI vast knowledge and your intelligence.. enter your query"
            />
            <button onClick={handleSubmit} style={styles.button} disabled={isLoading || isTyping}>
              {isLoading ? 'Sent' : 'Send'}
            </button>
          </div>
        </div>
      </div>
    </MasterLayout>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    backgroundColor: '#f5f5f5',
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  resultBox: {
    flex: 1,
    padding: '20px',
    overflowY: 'auto' as const, // Updated to satisfy TypeScript
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'stretch',
  },
  messageContainer: (role: string) => ({
    display: 'flex',
    flexDirection: role === 'user' ? 'row-reverse' as const : 'row' as const,
    alignItems: 'center',
    margin: '5px 0', // Reduced margin for compact look
  }),
  iconContainer: {
    marginRight: '10px',
    marginLeft: '10px',
  },
  icon: (role: string) => ({
    fontSize: '24px',  // Reduced size for a more compact view
    color: role === 'user' ? '#007bff' : '#62f757',
  }),
  message: (role: string) => ({
    padding: '8px 12px', // Adjusted padding to reduce height
    borderRadius: '12px',
    backgroundColor: role === 'user' ? '#62f757' : '#F4FFFD',
    alignSelf: role === 'user' ? 'flex-end' : 'flex-start',
    maxWidth: '75%',
    whiteSpace: 'pre-wrap' as const,
    wordWrap: 'break-word' as const,
    fontSize: '14px', // Reduced font size
    // maxHeight: '200px', // Limit message box height
    overflowY: 'auto' as const,  // Corrected for TypeScript
  }),
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ccc',
    backgroundColor: '#f9f9f9',
  },
  textArea: {
    flex: 1,
    height: '50px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    resize: 'none' as const,
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default FinGPT;
