import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import IncomeStep from './IncomeStep';
import AssetsStep from './AssetsStep';
import LiabilitiesStep from './LiabilitiesStep';
import ExpensesStep from './ExpensesStep';
import SummaryStep from './SummaryStep';
import './PersonalFinancialStatement.css';
import MasterLayout from './MasterLayout';
import PersonalInfoStep from './PersonalInformationStep';
import InsuranceStep from './InsuranceStep';
import GoalsStep from './GoalsStep';
import TaxationStep from './TaxationStep';
import OtherConsiderationsStep from './otherConsiderationsStep';
import RetirementStep from './RetirementStep';

const PersonalFinancialStatement: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const handlePrevSection = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNextSection = () => {
    setCurrentStep(currentStep + 1);
  };

  const steps = [
    {
      title: 'About You',
      component: <PersonalInfoStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    },
  
    {
      title: 'Your Wealth',
      component: <AssetsStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    },
    // {
    //   title: 'Liabilities',
    //   component: <LiabilitiesStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    // {
    //   title: 'Expenses',
    //   component: <ExpensesStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    {
      title: 'Your Financial Saftey',
      component: <InsuranceStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    },
    // {
    //   title: 'Taxation',
    //   component: <TaxationStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    //   {
    //   title: 'Your Wealth Growth',
    //   component: <IncomeStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    {
      title: 'Your Wealth Goals',
      component: <GoalsStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    },
    // {
    //   title: 'Retirement',
    //   component: <RetirementStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    // {
    //   title: 'Other Considerations',
    //   component: <OtherConsiderationsStep onNextSection={handleNextSection} onPrevSection={handlePrevSection} />
    // },
    {
      title: 'Summary',
      component: <SummaryStep onPrevSection={handlePrevSection} />
    }
  ];

  return (
    <MasterLayout>
    <div className="pfs-container">
      {/* Clickable Progress Bar */}
      <ProgressBar currentStep={currentStep} steps={steps} onStepClick={setCurrentStep} />

      {/* Current Step Content */}
      <div className="pfs-content">{steps[currentStep].component}</div>
    </div>
    </MasterLayout>
  );
};

export default PersonalFinancialStatement;
